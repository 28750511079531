<template>
    <div class="container u-f-ajb u-d">
        <Header :type="2" :current="current" @onClickTab="onClickTab"></Header>
        <div v-show="current === 1" class="main u-f-ajc u-d u-f1">
            <div class="u-f-ajc u-d">
                <div class="div-title">浙江艺云数字科技有限公司</div>
                <div class="div-content">
                    浙江艺云数字科技有限公司是浙江省文化和旅游厅牵头，由浙江演艺集团与九功体育文化共同成立，是政府独家授权合作公司，负责全省“文化艺术数媒服务平台”、未来社区“文E家”、未来乡村文化场景的建设和运营。
                </div>
                <img src="../assets/logo_shu.svg" />
                <div class="div-content2">
                    <p>
                        公司专注于艺术领域、深扎艺术市场，并通过互联网数字化，整合文化艺术资源，结合时兴运营手段，挖掘文化艺术价值，推进数字化改革。并以数媒为抓手，搭建智慧平台，植入优质内容，提升公共文化服务水平，实现平台数智。公司先后打造未来社区、未来乡村数字文化场景，实现多方位数字化智能管理，以文化艺术赋能，构建人民生活新图景。以“互联网+文化艺术”，打造新时代数字文创产品，实现数字文旅新突破。
                    </p>
                    <p>公司秉持“诚实、创新、务实”的理念，以一流的团队制定更成熟的方案，以一流的技术打造更优质的产品，以优质的服务更好地丰富人们的生活。</p>
                </div>
            </div>
        </div>

        <Footer :type="2"></Footer>
    </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';

export default {
    name: 'About',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            current: 1,
        };
    },
    methods: {
        onClickTab(currentIndex) {
            this.current = currentIndex;
            if (this.current === 0) {
                this.$router.replace({ name: 'Home' });
            } else if (this.current === 1) {
                this.$router.replace({ name: 'About', params: { index: currentIndex } });
            } else if (this.current === 2) {
                this.$router.replace({ name: 'ApplyWebsit', params: { index: currentIndex } });
            } else if (this.current === 3) {
                this.$router.replace({ name: 'ApplyShow', params: { index: currentIndex } });
            }
        },

    },
    created() {
        this.current = this.$route.params.index || 1;
    }
};
</script>

<style type="text/css" scoped="scoped">
/* 在宽小于或等于 1000 像素的屏幕上 */
@media screen and (max-width: 1000px) {
    .main {
        width: 100% !important;
    }
    .form {
        width: 90% !important;
    }
}
.container {
    height: 100%;
    background-color: #ffffff;
}
.main {
    font-size: 16px;
    width: 60%;
    color: #333333;
}
p {
    margin: 0;
}
.div-title {
    font-size: 36px;
    font-weight: 500;
    margin: 0 30px;
    margin-bottom: 30px;
}
.div-content {
    text-indent: 2em;
    margin: 0 30px;
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 36px;
}
.div-content2 {
    text-indent: 2em;
    margin: 0 30px;
    margin-top: 40px;
    font-size: 18px;
    line-height: 36px;
}

</style>
